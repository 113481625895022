import resume from "assets/pdf/Resume.pdf";
import { useEffect } from "react";
import { FiGithub, FiLinkedin, FiMail } from "react-icons/fi";
import { useLocation } from "react-router-dom";

export const NavBar = () => {
  const location = useLocation();

  useEffect(() => {
    document.title = `${getPageTitle(location.pathname)} Donovan Singh`;
  }, [location.pathname]);

  const getPageTitle = (path) => {
    switch (path) {
      case "/experience":
        return "Experience |";
      case "/projects":
        return "Projects |";
      default:
        return "";
    }
  };

  return (
    <div className="-translate-x-2 lg:top-20">
      <nav className="flex flex-row justify-between">
        <div className="flex flex-row">
          <a
            className="transition-all flex align-middle relative py-1 px-2"
            href="/"
          >
            home
          </a>
          <a
            className="transition-all hover:text-neutral-800 dark:hover:text-neutral-200 flex align-middle relative py-1 px-2"
            href="/experience"
          >
            experience
          </a>
          <a
            className="transition-all hover:text-neutral-800 dark:hover:text-neutral-200 flex align-middle relative py-1 px-2"
            href="/projects"
          >
            projects
          </a>
          <a
            href={resume}
            className="transition-all hover:text-neutral-800 dark:hover:text-neutral-200 flex align-middle relative py-1 px-2"
            target="_blank"
            rel="noreferrer"
          >
            resume
          </a>
        </div>
        <div class="flex flex-row justify-end">
          <a
            className="transition-all hover:text-neutral-800 dark:hover:text-neutral-200 flex align-middle relative py-2"
            href="mailto:don9617@hotmail.com"
          >
            <FiMail className="ml-2 text-black mb-1" />
          </a>
          <a
            className="transition-all hover:text-neutral-800 dark:hover:text-neutral-200 flex align-middle relative py-2"
            href="https://github.com/Donovan9617"
          >
            <FiGithub className="ml-2 text-black mb-1" />
          </a>
          <a
            className="transition-all hover:text-neutral-800 dark:hover:text-neutral-200 flex align-middle relative py-2"
            href="https://www.linkedin.com/in/donovan-singh-361a9b207/"
          >
            <FiLinkedin className="ml-2 text-black mb-1" />
          </a>
        </div>
      </nav>
    </div>
  );
};
