import profilepic from "assets/img/profilepic.jpg";

export const Home = () => {
  return (
    <div>
      <div className="flex flex-col items-center justify-center">
        <img
          className="rounded-full object-cover h-40 w-40 mb-8"
          width="763"
          height="764"
          src={profilepic}
          alt="Profile"
        />
        <h1 className="font-medium text-2xl mb-8 tracking-tighter">
          hello world!👋🏻
        </h1>
      </div>
      <p className="prose prose-neutral dark:prose-invert">
        I'm Donovan. I live in Singapore and am currently a final year student
        at the{" "}
        <a href="https://nus.edu.sg" className="underline">
          National University of Singapore (NUS)
        </a>{" "}
        majoring in Computer Science. While in NUS, I also studied under the{" "}
        <a href="https://www.usp.nus.edu.sg/" className="underline">
          University Scholars Programme (USP)
        </a>
        , where I wrote essays on various topics which you may view{" "}
        <a
          href="https://github.com/Donovan9617/USP_Papers"
          className="underline"
        >
          here
        </a>
        .
      </p>
      <br />
      <p className="prose prose-neutral dark:prose-invert">
        I wrote my first line of code during high school as a student researcher
        under the NUS Science Research Programme. Since then, I have enjoyed
        learning about distributed algorithms and database systems in
        university. I was also a tech lead in{" "}
        <a href="https://nususc.com/about" className="underline">
          USC
        </a>
        , a student community in USP committed to improving college life.
      </p>
      <br />
      <p className="prose prose-neutral dark:prose-invert">
        Outside of work, I love reading books, going to the park, playing table
        tennis, and travelling the world.
      </p>
    </div>
  );
};
