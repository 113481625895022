import { CustomCard } from "components/CustomCard";

export const Experience = () => {
  return (
    <div>
      <h1 class="font-medium text-2xl mb-8 tracking-tighter">
        software engineering
      </h1>
      <div class="prose prose-neutral dark:prose-invert">
        <CustomCard
          title="OKX"
          subtitle1="Incoming Backend Engineer, From June 2025"
          description={["Centralised Finance, Growth team."]}
        />
        <CustomCard
          title="Standard Chartered"
          subtitle1="Software Engineering Intern, May 2024 - August 2024"
          description={[
            "I spent summer 2024 interning at ",
            <a className="underline" href="https://www.sc.com/">
              Standard Chartered
            </a>,
            " with the Corporate & Investment Banking, E-Commerce, Blade team. ",
            "I developed full-stack features on the ",
            <a
              className="underline"
              href="https://www.sc.com/en/corporate-investment-banking/markets-platform/"
            >
              Standard Chartered Markets
            </a>,
            " application that supports pricing and trading of cross-asset derivatives products. ",
            "I worked on refactoring core backend services, migrating components from Scala to Java ",
            "to enhance scalability.",
          ]}
        />
        <CustomCard
          title="Shopee"
          subtitle1="Data Engineering Intern, January 2024 - April 2024"
          description={[
            "I spent Spring 2024 interning at ",
            <a className="underline" href="https://shopee.com/">
              Shopee
            </a>,
            " (part of the ",
            <a className="underline" href="https://www.sea.com/home">
              Sea Group
            </a>,
            ") with the Marketplace, Regional Sellers team. ",
            "I designed schemas and optimised queries for processing Shopee traffic data for ",
            <a className="underline" href="https://seller.shopee.sg/edu/home">
              Seller Education Hub
            </a>,
            " and ",
            <a
              className="underline"
              href="https://seller.shopee.sg/edu/article/10153/about-seller-missions"
            >
              Seller Missions
            </a>,
            ", built data pipelines processing sellers' metrics data for ",
            <a
              className="underline"
              href="https://seller.shopee.sg/edu/article/7048"
            >
              Preferred Sellers
            </a>,
            ", and designed regex for detecting seller accounts with fradulent shop names.",
          ]}
        />
        <CustomCard
          title="HTX"
          subtitle1="Software Engineering Intern, May 2023 - August 2023"
          description={[
            "I spent summer 2023 interning at ",
            <a className="underline" href="https://www.htx.gov.sg/">
              Home Team Science & Technology Agency
            </a>,
            ", where I developed full-stack features on HTX's Cloud Evidence Portal to provide evidence capturing capabilities to police offcers.",
            " I built infrastructure-as-code deployment architecture and architected microservices.",
          ]}
        />
        <CustomCard
          title="Tinkertanker"
          subtitle1="Software Engineering Intern, May 2022 - August 2022"
          description={[
            "I spent early summer 2022 interning at ",
            <a className="underline" href="https://tinkertanker.com/">
              Tinkertanker
            </a>,
            ", where I developed full-stack features on an administration portal as well as frontend features on the learning management system ",
            <a className="underline" href="https://www.echoclass.com/">
              EchoClass
            </a>,
            ".",
          ]}
        />
        <hr class="my-6 border-neutral-100 dark:border-neutral-800" />

        <h1 class="font-medium text-2xl mb-8 tracking-tighter">teaching</h1>
        <CustomCard
          title="Software Development Project"
          subtitle1="National University of Singapore, Summer 2023, Summer 2024"
          description={[
            "I was a teaching assistant for two iterations of ",
            <a
              className="underline"
              href="https://nusmods.com/modules/CP2106/independent-software-development-project-orbital"
            >
              CP2106: Software Development Project
            </a>,
            " taught by Professor Zhao Jin. My responsibilities included teaching software engineering practices, providing sprint consultations and grading submissions.",
          ]}
        />
      </div>
    </div>
  );
};
