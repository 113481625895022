import "app/App.css";
import { NavBar } from "components/NavBar";
import { Experience } from "pages/Experience";
import { Home } from "pages/Home";
import { Projects } from "pages/Projects";
import { BrowserRouter, Route } from "react-router-dom";

export const App = () => {
  return (
    <div className="antialiased max-w-2xl mb-40 flex flex-col md:flex-row mt-8 mx-auto">
      <main class="flex-auto min-w-0 mt-6 flex flex-col md:px-0 ml-4 mr-4">
        <BrowserRouter>
          <NavBar />
          <div className="mt-16">
            <Route exact path="/" component={Home} />
            <Route path="/experience" component={Experience} />
            <Route path="/projects" component={Projects} />
          </div>
        </BrowserRouter>
      </main>
    </div>
  );
};
