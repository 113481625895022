export const CustomCard = ({ title, subtitle1, subtitle2, description }) => {
  return (
    <div>
      <hr class="my-6 border-neutral-100 dark:border-neutral-800" />

      <h2 class="font-medium text-xl mb-1 tracking-tighter">{title}</h2>
      <p class="text-neutral-600 dark:text-neutral-400 text-sm mb-4">
        {subtitle1}
        <br />
        {subtitle2}
      </p>
      <p>{description}</p>
    </div>
  );
};
